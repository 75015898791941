import { Router } from '@gatsbyjs/reach-router'
import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { Products } from '../../components/Products'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SessionTimeoutModal accountType={AccountType.User} />
        <Router basepath="/:lang/products">
          <Products path="*" />
        </Router>
      </BasicLayout>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Products</title>

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { AccountType } from '../../requests'
import { routes } from '../../routes'

export const RedirectNoAccessWrapper: React.FC<
  RouteComponentProps<{ children?: React.ReactNode; hasNoAccess: boolean }>
> = ({ hasNoAccess, children }) => {
  if (hasNoAccess) navigate(routes.notFound(AccountType.User))

  return hasNoAccess ? null : <>{children}</>
}
